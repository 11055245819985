import React from 'react'
import RazorpayForm from '../component/Razorpay'

const Donation = () => {
  return (
    <div style={{display:"flex", width:"100vw", height:"100vh", backgroundColor:"#176B87"}}>
        <RazorpayForm />
    </div>
  )
}

export default Donation